import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';
import { displayPrice } from '../../util/configHelpers';
import { formatMoneyWithoutFragment } from '../../util/currency';

import PriceContainerOfferForm from './PriceContainerOfferForm';

import css from './OrderPanel.module.css';

const PriceContainer = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    listing,
    priceOnly = false,
    handleOfferPriceOrderSubmit,
    marketplaceCurrency
  } = props;
  const { listingType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  return (
    <div className={css.priceContainer}>
      <div className={classNames(css.priceCol, { [css.priceOnly]: priceOnly })}>
        {!priceOnly && (
          <div className={css.tip}>
            <FormattedMessage id="OrderPanel.PriceContainer.buyNowFor" />
          </div>
        )}
        <p className={css.price}>{formatMoneyWithoutFragment(intl, price)}</p>
      </div>
      {!priceOnly && (
        <div className={css.makeAnOfferCol}>
          <div className={css.tip}>
            <FormattedMessage id="OrderPanel.PriceContainer.makeAnOffer" />
          </div>
          <PriceContainerOfferForm
            formId="OrderPanelInquiryForm"
            onSubmit={handleOfferPriceOrderSubmit}
            listing={listing}
            marketplaceCurrency={marketplaceCurrency}
          />
        </div>
      )}
    </div>
  );
};

export default PriceContainer;
