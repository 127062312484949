import React, { useEffect } from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { types as sdkTypes } from '../../util/sdkLoader';
import { FormattedMessage, useIntl } from '../../util/reactIntl';
import { parse } from '../../util/urlHelpers';
import appSettings from '../../config/settings';
import configDefault from '../../config/configDefault';
import { useConfiguration } from '../../context/configurationContext';
import { Form, PrimaryButton, FieldCurrencyInput, IconSpinner } from '../../components';

import css from './OrderPanel.module.css';

const { Money } = sdkTypes;

const RenderForm = formRenderProps => {
  const {
    formId,
    className,
    rootClassName,
    handleSubmit,
    intl,
    fetchLineItemsInProgress,
    dirtySinceLastSubmit,
    submitSucceeded,
    offerPrice,
    pristine,
    values
  } = formRenderProps;
  const t = intl.formatMessage;
  const classes = classNames(rootClassName || css.offerPriceForm, className);

  useEffect(() => {
    handleSubmit();
  }, [offerPrice])

  return (
    <Form id={formId} onSubmit={handleSubmit} className={classes}>
      <FieldCurrencyInput
        id={`${formId}.price`}
        name="price"
        className={css.offerPriceField}
        placeholder={t({
          id: 'OrderPanel.PriceContainer.offerPricePlaceholder',
        })}
        currencyConfig={appSettings.getCurrencyFormatting(configDefault.currency)}
      />

      <div className={css.offerSubmitButton}>
        <PrimaryButton
          type="submit"
          disabled={fetchLineItemsInProgress || (submitSucceeded && !dirtySinceLastSubmit) || (!!values?.price && pristine)}
        >
          {fetchLineItemsInProgress ? (
            <IconSpinner />
          ) : (
            <FormattedMessage id="OrderPanel.PriceContainer.offerSubmitButton" />
          )}
        </PrimaryButton>
      </div>
    </Form>
  );
};

const PriceContainerOfferForm = props => {
  const intl = useIntl();
  const config = useConfiguration();
  const fetchLineItemsInProgress = useSelector(state => state.ListingPage.fetchLineItemsInProgress);
  const location = useLocation();
  const { offerPrice } = parse(location.search) || {};
  const { listing, marketplaceCurrency } = props;

  // eslint-disable-next-line no-unused-vars
  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const deliveryMethod =
    shippingEnabled && !pickupEnabled
      ? 'shipping'
      : !shippingEnabled && pickupEnabled
      ? 'pickup'
      : null;
  const initialValues = {
    price: offerPrice ? new Money(offerPrice, listing?.attributes?.price?.currency || marketplaceCurrency) : undefined,
    deliveryMethod,
  };

  return (
    <FinalForm
      {...props}
      initialValues={initialValues}
      intl={intl}
      config={config}
      fetchLineItemsInProgress={fetchLineItemsInProgress}
      render={RenderForm}
      offerPrice={offerPrice}
    />
  );
};

export default PriceContainerOfferForm;
