import React from 'react';
import classNames from 'classnames';

import { FormattedMessage } from '../../util/reactIntl';

import fireFlameIcon from './images/fireFlame.png';
import css from './ViewCounter.module.css';

const THRESHOLD_COUNT = 5;

const ViewCounter = props => {
  const { listing, className } = props;
  const publicData = listing?.attributes?.publicData || {};
  const { viewCount = 0 } = publicData || {};

  if (viewCount < THRESHOLD_COUNT) {
    return null;
  }

  return (
    <div className={classNames(css.viewCounter, className)}>
      <div className={css.flames}>
        <div className={css.icon}>
          <img alt="fire flame" src={fireFlameIcon} className={css.flamesImage}/>
        </div>
      </div>
      <div className={css.text}>
        <div className={css.desirable}>
          <FormattedMessage id="ViewCounter.veryDesirableProduct" />
        </div>
        <div className={css.count}>
          <FormattedMessage id="ViewCounter.peopleAreInterested" values={{ count: viewCount }} />
        </div>
      </div>
    </div>
  );
};

export default ViewCounter;
